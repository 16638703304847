// extracted by mini-css-extract-plugin
export var posts = "posts-module--posts--+ha9l";
export var hero = "posts-module--hero--fYxNN";
export var header = "posts-module--header--Lqtsa";
export var card = "posts-module--card--abh51";
export var thumbnail = "posts-module--thumbnail--MckYX";
export var alternateImage = "posts-module--alternateImage--PIbFQ";
export var chip = "posts-module--chip--Kk5+b";
export var excerpt = "posts-module--excerpt--RFPJz";
export var footer = "posts-module--footer--QKHtG";
export var readMore = "posts-module--readMore--sCADj";